import { COUNTRIES, REQUIRED_ADDRESS_FIELDS } from './constants';
import { some, every, isEmpty, isEqual } from 'lib/javascript';
import {
  Address,
  AddressFormModel,
  AddressPayload,
  AddressType,
} from './types';

export const findAddressByType = (
  type: AddressType,
  addresses: Address[],
) => addresses.find((address) => address.type === type);

export const formatAddress = (address: Address): string => {
  const {
    company_name,
    address_1,
    address_2,
    address_3,
    postal_code,
  } = address;

  const formattedAddress: (string | null | undefined)[] = [
    company_name,
    address_1,
    address_2,
    address_3,
    postal_code,
    COUNTRIES.SINGAPORE,
  ].filter((field) => !isEmpty(field));

  return formattedAddress.join('\n');
};

export const isIncompleteAddress = (address?: Address): boolean =>
  !isEmptyAddress(address) &&
  some(REQUIRED_ADDRESS_FIELDS, (field) => isEmpty(address?.[field]));

export const isEmptyAddress = (address?: Address): boolean =>
  !address ||
  every(REQUIRED_ADDRESS_FIELDS, (field) => isEmpty(address[field]));

export const isBillingAddressAndShippingAddressSame = (
  billingAddress,
  shippingAddress,
) => {
  const {
    id: billingAddressId,
    type: billingAddressType,
    delivery_notes: billingDeliveryNotes,
    ...billingAddressWithoutIdAndTypeAndNotes
  } = billingAddress;
  const {
    id: shippingAddressId,
    type: shippingAddressType,
    delivery_notes: shippingDeliveryNotes,
    ...shippingAddressWithoutIdAndTypeAndNotes
  } = shippingAddress;

  return isEqual(
    billingAddressWithoutIdAndTypeAndNotes,
    shippingAddressWithoutIdAndTypeAndNotes,
  );
};

export const isBillingOrShippingDoNotHaveUnitNumber = (
  billingAddress: Address,
  shippingAddress: Address,
) => {
  return {
    billing: billingAddress.address_3 === '',
    shipping: shippingAddress.address_3 === '',
  };
};

export const hasAddressesBeenSetup = (
  addresses: Address[],
  isCollectionPoint?: boolean,
) => {
  const billingAddress = findAddressByType('billing', addresses);
  const shippingAddress = findAddressByType('shipping', addresses);

  const isValidBillingAddress =
    !isEmptyAddress(billingAddress) &&
    !isIncompleteAddress(billingAddress);

  if (isCollectionPoint) {
    return isValidBillingAddress;
  }

  return (
    isValidBillingAddress &&
    !isEmptyAddress(shippingAddress) &&
    !isIncompleteAddress(shippingAddress)
  );
};

export const getAddressesByType = (
  type: AddressType,
  addresses: Address[],
): Address[] => addresses.filter((address) => address.type === type);

export const getFloorAndUnitNumber = (
  address?: string | null,
): { floor: string; unit: string } => {
  const blocks = address?.split('-');
  const floor = blocks?.[0]?.replace('#', '') ?? '';
  const unit = blocks?.[1] ?? '';

  return { floor, unit };
};

export const formatFloorAndUnitNumber = (
  floor?: string,
  unit?: string,
): string => {
  const formattedFloor = floor ? `#${floor}` : '';
  const formattedUnit = unit ? `-${unit}` : '';
  return `${formattedFloor}${formattedUnit}`;
};

export const generatePayload = (
  address: AddressFormModel,
): AddressPayload => {
  const {
    id,
    label,
    first_name,
    last_name,
    company_name,
    phone_number,
    postal_code,
    address_1,
    address_2,
    floor_no,
    unit_no,
    delivery_notes,
    type,
    is_manual,
    without_floor_and_unit,
  } = address;

  let address_3: string | null = '';
  if (is_manual) {
    address_3 = without_floor_and_unit
      ? null
      : formatFloorAndUnitNumber(floor_no, unit_no);
  } else {
    address_3 = formatFloorAndUnitNumber(floor_no, unit_no);
  }

  return {
    id,
    label,
    first_name,
    last_name,
    company_name,
    phone_number,
    postal_code,
    address_1,
    address_2,
    address_3,
    delivery_notes,
    type,
    is_manual,
  };
};

export const sortAddressesByDefault = (
  addresses: Address[],
): Address[] => {
  return addresses.sort((a, b) => {
    if (a.default === b.default) {
      return 0;
    }
    return a.default ? -1 : 1;
  });
};
